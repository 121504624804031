//import d3
import * as d3 from 'd3';
import { useState, useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoidmVsaWdhc3AiLCJhIjoiY2xlMnMzdzJoMDF3azNvbzRtejdhNTJjMSJ9.lGP7ol4DOgGHhCnwZiFupg';

const useResizeObserver = (ref) => {
	const [dimensions, setDimensions] = useState(null);
	useEffect(() => {
		const observeTarget = ref.current;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setDimensions(entry.contentRect);
			});
		});
		resizeObserver.observe(observeTarget);
		return () => {
			resizeObserver.unobserve(observeTarget);
		};
	}, [ref]);

	return dimensions;
};

// const margin = {
//   top: 40,
//   right: 80,
//   bottom: 60,
//   left: 80,
// };

function PropertiesMap({ data }) {
	// const svgRef = useRef();
	// const mapContainer = useRef();
	const mapContainer = useRef();
	const wrapDim = useResizeObserver(mapContainer);
	const map = useRef(null);
	const [lng, setLng] = useState(-98.11);
	const [lat, setLat] = useState(39.05);
	const [zoom, setZoom] = useState(4.24);

	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			// style: "mapbox://styles/mapbox/streets-v12",
			style: 'mapbox://styles/veligasp/cle2sozvl001a01o36k6uivjv',
			center: [lng, lat],
			zoom: zoom,
		});
	});

	useEffect(() => {
		if (!map.current) return; // wait for map to initialize

		map.current.on('move', () => {
			setLng(map.current.getCenter().lng.toFixed(4));
			setLat(map.current.getCenter().lat.toFixed(4));
			setZoom(map.current.getZoom().toFixed(2));
		});

		// const container = useResizeObserver(mapContainer);

		const width = mapContainer.current.clientWidth;
		const height = mapContainer.current.clientHeight;

		// Setup our svg layer that we can manipulate with d3
		// const bb = container.element.getBoundingClientRect();
		let svgOverlaySelection = d3.select('.map-container').selectAll('.zeustraMapOverlay').data([null]);

		const svgOverlayEnter = svgOverlaySelection
			.enter()
			.append('svg')
			.classed('zeustraMapOverlay', true)
			.style('position', 'absolute')
			.style('top', 0)
			.style('left', 0)
			.style('pointer-events', 'none'); // the svg shouldn't capture mouse events, so we can have pan and zoom from mapbox

		svgOverlaySelection.merge(svgOverlayEnter).attr('width', width).attr('height', height);

		// let rectSelection = svgOverlaySelection.selectAll("rect").data([null]);

		// const rectEnter = rectSelection
		//   .enter()
		//   .append("rect")
		//   .attr("fill", "yellow")
		//   .attr("stroke", "purple")
		//   .attr("stroke-width", 12)
		//   .attr("fill-opacity", 0.1);

		// rectSelection.merge(rectEnter).attr("width", width).attr("height", height);

		const atlanta = [-84.388, 33.749];
		const atlantaPoint = projectPoint(atlanta[0], atlanta[1]);

		//Projection function
		var transform = d3.geoTransform({ point: projectPoint });
		var path = d3.geoPath().projection(transform);

		const atlantaSel = svgOverlaySelection.selectAll('circle').data([atlanta]);

		atlantaSel.enter().append('circle').attr('fill', '#F0C755').attr('stroke', '#F0C755').attr('fill-opacity', 0.5);

		atlantaSel
			.merge(atlantaSel)
			.attr('cx', atlantaPoint.x)
			.attr('cy', atlantaPoint.y)
			.attr('r', (10 * zoom) / 2);
	});

	//Project any point to map's current state
	const projectPoint = (lon, lat) => map.current.project(new mapboxgl.LngLat(lon, lat));

	return (
		<div>
			{/* <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div> */}
			<div ref={mapContainer} className="map-container" />
		</div>
	);
}

export default PropertiesMap;
