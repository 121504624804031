import * as d3 from 'd3';
import { useState, useEffect, useRef } from 'react';

const useResizeObserver = (ref) => {
	const [dimensions, setDimensions] = useState(null);
	useEffect(() => {
		const observeTarget = ref.current;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setDimensions(entry.contentRect);
			});
		});
		resizeObserver.observe(observeTarget);
		return () => {
			resizeObserver.unobserve(observeTarget);
		};
	}, [ref]);

	return dimensions;
};

// const channels = ['Phone', 'Email', 'Text', 'Meeting', 'Website'];

// ACTIVITY------------------------CHANNEL
// Received Call-------------------Calls
// Completed Call------------------Calls
// Received Email------------------Emails
// Replied to Email----------------Emails
// Visited Page--------------------Emails
// Clicked Link--------------------Emails
// Completed Form------------------Emails
// Attended Meeting----------------Meetings
// Scheduled Meeting---------------Meetings
// Received Text-------------------Texts
// Answered Text-------------------Texts
// Had Task Scheduled--------------Ignore
// Email Replaced------------------Ignore
// Opened Email--------------------Ignore
// Marketing Ended-----------------Ignore
// Added to Marketing--------------Ignore
// Opted Out of Email--------------Ignore
// Email Bounced-------------------Ignore

const margin = {
	top: 40,
	right: 80,
	bottom: 60,
	left: 80,
};

const colors = ['#F0CAA3', '#C060A1'];

const channelMapping = {
	Calls: {
		inbound: ['Received Call'],
		outbound: ['Completed Call'],
	},
};

function ChannelTrendline({ data, channel }) {
	const svgRef = useRef();
	const wrapperRef = useRef();
	const wrapDim = useResizeObserver(wrapperRef);

	//create a filteredData array, which contains only the data with the Activity_Date__c within the last year
	const startDate = new Date();
	startDate.setMonth(startDate.getMonth() - 5);
	const filteredData = data.filter((d) => d.Activity_Date__c > startDate);

	const inboundData = filteredData.filter((d) => channelMapping[channel].inbound.includes(d.Activity_Type__c));
	const outboundData = filteredData.filter((d) => channelMapping[channel].outbound.includes(d.Activity_Type__c));

	useEffect(() => {
		if (!wrapDim) return;

		console.log('inboundData', inboundData);
		console.log('outboundData', outboundData);

		const svg = d3.select(svgRef.current);
		const mainGroup = svg.select('#mainGroup');

		const width = wrapDim.width;
		const height = wrapDim.height;

		svg
			.attr('width', width)
			.attr('height', height)
			.attr('viewBox', [0, 0, width, height])
			.attr('preserveAspectRatio', 'xMidYMid meet');
		// .style('background', '#0d0d0d');

		//construct x scale, and axis, based on the Activity_Date__c property
		const xScale = d3
			.scaleTime()
			.domain(d3.extent(filteredData, (d) => d.Activity_Date__c))
			.range([margin.left, width - margin.right]);

		const xAxis = d3.axisBottom(xScale).ticks(6);

		const xAxisGroup = mainGroup.selectAll('.x-axis').data([null]);

		xAxisGroup
			.enter()
			.append('g')
			.attr('class', 'axis x-axis')
			.merge(xAxisGroup)
			.attr('transform', `translate(0, ${height - margin.bottom})`)
			.call(xAxis);

		//group data by day, using the Activity_Date__c property and d3.rollup. take care to group by the date, not the full timestamp
		const inboundGrouped = d3
			.rollups(
				inboundData,
				(v) => v.length,
				(d) => d3.timeWeek(d.Activity_Date__c)
			)
			.sort((a, b) => d3.ascending(a[0], b[0]));

		const outboundGrouped = d3
			.rollups(
				outboundData,
				(v) => v.length,
				(d) => d3.timeWeek(d.Activity_Date__c)
			)
			.sort((a, b) => d3.ascending(a[0], b[0]));

		//construct y scale, and axis, based on the number of activities per day
		const yScaleInbound = d3
			.scaleLinear()
			.domain([0, d3.max(inboundGrouped, (d) => d[1])])
			.range([height - margin.bottom, margin.top])
			.clamp(true);

		//construct additional y scale for outbound
		const yScaleOutbound = d3
			.scaleLinear()
			.domain([0, d3.max(outboundGrouped, (d) => d[1])])
			.range([height - margin.bottom, margin.top])
			.clamp(true);

		const yAxisInbound = d3.axisLeft(yScaleInbound);

		let yAxisInboundGroup = mainGroup.selectAll('.y-axis-inbound').data([null]);

		yAxisInboundGroup
			.enter()
			.append('g')
			.attr('class', 'axis y-axis y-axis-inbound')
			.merge(yAxisInboundGroup)
			//   .call(yGrid)
			.attr('transform', `translate(${margin.left}, 0)`)
			.call(yAxisInbound)
			.call((g) => g.select('.axis-label').remove())
			.call((g) => g.select('rect').remove())
			.call(
				(g) =>
					g
						.append('rect')
						.attr('x', 0)
						.attr('y', margin.top - 24)
						.attr('width', 16)
						.attr('height', 16)
						.attr('fill', colors[0])
				// todo delete .text("Weekly sales activities")
			)
			.call(
				(g) =>
					g
						.append('text')
						.attr('class', 'axis-label')
						.attr('x', 22)
						.attr('y', margin.top - 10)
						.attr('text-anchor', 'start')
						.attr('fill', 'white')
						.attr('font-size', 16)
						.attr('font-family', 'Montserrat, sans-serif')
						.text('inbound')
				// todo delete .text("Weekly sales activities")
			);

		const yAxisOutbound = d3.axisRight(yScaleOutbound);
		const yAxisOutboundGroup = mainGroup.selectAll('.y-axis-outbound').data([null]);

		yAxisOutboundGroup
			.enter()
			.append('g')
			.attr('class', 'axis y-axis y-axis-outbound')
			.merge(yAxisOutboundGroup)
			//   .call(yGrid)
			.attr('transform', `translate(${width - margin.right}, 0)`)
			.call(yAxisOutbound)
			.call((g) => g.select('.axis-label').remove())
			.call((g) => g.select('rect').remove())
			.call(
				(g) =>
					g
						.append('rect')
						.attr('x', -16)
						.attr('y', margin.top - 24)
						.attr('width', 16)
						.attr('height', 16)
						.attr('fill', colors[1])
				// todo delete .text("Weekly sales activities")
			)
			.call(
				(g) =>
					g
						.append('text')
						.attr('class', 'axis-label')
						.attr('x', -24)
						.attr('y', margin.top - 10)
						.attr('text-anchor', 'end')
						.attr('fill', 'white')
						.attr('font-size', 16)
						.attr('font-family', 'Montserrat, sans-serif')
						.text('outbound')
				// todo delete .text("Weekly sales activities")
			);		

		//construct a line chart, using the movingAverage array
		const line = d3
			.line()
			.x((d) => xScale(d[0]))
			.y((d) => yScaleInbound(d[1]))
			.curve(d3.curveBasis);

		// const area = d3
		// 	.area()
		// 	.x((d) => xScaleBand(d[0]))
		// 	.y0(yScale(0))
		// 	.y1((d) => yScale(d[1]))
		// 	.curve(d3.curveBasis);

		// let movingAverageGroup = mainGroup.selectAll('.movingAverageGroup').data([null]);

		// const enterMov = movingAverageGroup.enter().append('g').attr('class', 'movingAverageGroup'); //.append("path");

		mainGroup.selectAll('.trendLine').remove();

		mainGroup
			.append('path')
			.attr('class', 'trendLine inbound')
			.attr('fill', 'none')
			.attr('stroke', colors[0])
			.attr('stroke-width', 3)
			.attr('d', line(inboundGrouped));

		mainGroup
			.append('path')
			.attr('class', 'trendLine outbound')
			.attr('fill', 'none')
			.attr('stroke', colors[1])
			.attr('stroke-width', 3)
			.attr('d', line(outboundGrouped));

		// enterMov
		// 	.append('path')
		// 	.attr('class', 'trendLine')
		// 	.attr('fill', 'none')
		// 	.attr('stroke', '#F2B705')
		// 	.attr('stroke-width', 2);
		// enterMov.append('path').attr('class', 'trendArea').attr('fill', '#F2B705').attr('fill-opacity', 0.1);

		// movingAverageGroup = movingAverageGroup.merge(movingAverageGroup.enter());

		// movingAverageGroup.select('.trendArea').attr('d', area(movingAverage));
		// movingAverageGroup.select('.trendLine').attr('d', line(movingAverage));

		// d3.select('.bars').raise();
	}, [filteredData, wrapDim, inboundData, outboundData]);

	return (
		<div>
			<div ref={wrapperRef} className="inout-wrapper">
				<svg ref={svgRef}>
					<g id="mainGroup"></g>
				</svg>
			</div>
			{/* <div className="legendDiv"></div> */}
		</div>
	);
}

export default ChannelTrendline;
