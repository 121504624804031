//import d3
import * as d3 from "d3";
import { useState, useEffect, useRef } from "react";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

const example = {
  Activity_Age__c: 13,
  Activity_Buckets__c: "13 days",
  Activity_Date__c: new Date(),
  Activity_Description__c: "Bad Number. Number disconnected",
  Activity_Owner__c: "Elicia Heaston",
  Activity_Type__c: "Received Call",
  Call_Result__c: "Meaningful Connect",
  Company_Name__c: "Hand and Shoulder Center",
  Contact_Name__c: "Glenn Buterbaugh",
  Day__c: "Thursday, January 19, 2023",
  Id: "a6r6e0000069yUrAAI",
  Number_Called__c: "724-321-1051",
  Responded__c: 0,
  Summary__c:
    "Glenn Buterbaugh received a call from Elicia Heaston 13 days ago.",
};

const margin = {
  top: 40,
  right: 80,
  bottom: 60,
  left: 80,
};

function EngagementChart({ data }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const wrapDim = useResizeObserver(wrapperRef);

  //create a filteredData array, which contains only the data with the Activity_Date__c within the last year
  const yearAgo = new Date();
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);
  const filteredData = data.filter((d) => d.Activity_Date__c > yearAgo);

  useEffect(() => {
    if (!wrapDim) return;

    const svg = d3.select(svgRef.current);
    const mainGroup = svg.select("#mainGroup");

    const width = wrapDim.width;
    const height = wrapDim.height;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("preserveAspectRatio", "xMidYMid meet");
    //   .style("background", "#0d0d0d");

    // console.log(data[0]);

    //construct x scale, and axis, based on the Activity_Date__c property
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(filteredData, (d) => d.Activity_Date__c))
      .range([margin.left, width - margin.right]);

    const xAxis = d3.axisBottom(xScale);

    const xAxisGroup = mainGroup.selectAll(".x-axis").data([null]);

    xAxisGroup
      .enter()
      .append("g")
      .attr("class", "axis x-axis")
      .merge(xAxisGroup)
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(xAxis);

    //group data by day, using the Activity_Date__c property and d3.rollup. take care to group by the date, not the full timestamp
    const dayData = d3
      .rollups(
        filteredData,
        (v) => v.length,
        // (d) => d3.timeDay(d.Activity_Date__c)
        (d) => d3.timeWeek(d.Activity_Date__c)
      )
      .sort((a, b) => d3.ascending(a[0], b[0]));

    const quant95 = d3.quantile(dayData, 0.99, (d) => d[1]);

    //construct y scale, and axis, based on the number of activities per day
    const yScale = d3
      .scaleLinear()
      .domain([0, quant95])
      .range([height - margin.bottom, margin.top])
      .clamp(true);

    const yAxis = d3.axisLeft(yScale);

    const yAxisGroup = mainGroup.selectAll(".y-axis").data([null]);

    yAxisGroup
      .enter()
      .append("g")
      .attr("class", "axis y-axis")
      .merge(yAxisGroup)
      //   .call(yGrid)
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(yAxis)
      .call((g) => g.select(".axis-label").remove())
      .call((g) =>
        g
          .append("text")
          .attr("class", "axis-label")
          .attr("x", 10)
          .attr("y", margin.top + 15)
          .attr("text-anchor", "start")
          // todo delete .text("Weekly sales activities")
      );

    //array from the keys of dayData
    const dayDataKeys = dayData.map((d) => d[0]);

    //construct a scaleBand, using the dayData array
    const xScaleBand = d3
      .scaleBand()
      .domain(dayDataKeys)
      .range([margin.left, width - margin.right])
      .padding(0.25);

    const rectGroup = mainGroup.selectAll(".bars").data([null]);

    rectGroup.enter().append("g").attr("class", "bars").attr("fill", "#3C75BF");

    //construct a bar chart, using the dayData array
    mainGroup
      .select(".bars")
      //   .merge(rectGroup.enter())
      .selectAll("rect")
      .data(dayData)
      .join("rect")
      .attr("x", (d) => xScaleBand(d[0]))
      .attr("y", (d) => yScale(d[1]))
      //   .attr('stroke', '#0d0d0d')
      .attr("width", xScaleBand.bandwidth())
      .attr("height", (d) => yScale(0) - yScale(d[1]))
      .on("mouseover", function (event, d) {
        d3.select(this).attr("fill", "#F2B705");

        mainGroup
          .append("text")
          .attr("class", "tooltip")
          .attr("x", xScaleBand(d[0]) + xScaleBand.bandwidth() / 2)
          .attr("y", yScale(d[1]) - 10)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          //   .attr("font-weight", "bold")
          .attr("fill", "#F2B705")
          .text(d3.format(",")(d[1]));
      })
      .on("mouseout", function (event, d) {
        d3.select(this).attr("fill", "#3C75BF");
        mainGroup.select(".tooltip").remove();
      });

    //   .attr("fill", "steelblue");

    // console.log(dayData);

    //construct a moving average, using the dayData array, that keeps a rolling average of the last 7 days
    const windowDays = 7;
    const movingAverage = dayData.map((d, i) => {
      //   console.log("idem po datum: ", d[0]);
      const window = dayData.slice(Math.max(0, i - windowDays), i + 1);
      const sum = window.reduce((a, b) => a + b[1], 0);
      const avg = sum / window.length;
      return [d[0], avg];
    });

    // console.log(movingAverage);

    //construct a line chart, using the movingAverage array
    const line = d3
      .line()
      .x((d) => xScaleBand(d[0]))
      .y((d) => yScale(d[1]))
      .curve(d3.curveBasis);

    const area = d3
      .area()
      .x((d) => xScaleBand(d[0]))
      .y0(yScale(0))
      .y1((d) => yScale(d[1]))
      .curve(d3.curveBasis);

    let movingAverageGroup = mainGroup
      .selectAll(".movingAverageGroup")
      .data([null]);

    const enterMov = movingAverageGroup
      .enter()
      .append("g")
      .attr("class", "movingAverageGroup"); //.append("path");

    enterMov
      .append("path")
      .attr("class", "trendLine")
      .attr("fill", "none")
      .attr("stroke", "#F2B705")
      .attr("stroke-width", 2);
    enterMov
      .append("path")
      .attr("class", "trendArea")
      .attr("fill", "#F2B705")
      .attr("fill-opacity", 0.1);

    movingAverageGroup = movingAverageGroup.merge(movingAverageGroup.enter());

    movingAverageGroup.select(".trendArea").attr("d", area(movingAverage));
    movingAverageGroup.select(".trendLine").attr("d", line(movingAverage));

    d3.select(".bars").raise();
  }, [filteredData, wrapDim]);

  return (
    <div ref={wrapperRef} className="engagement-wrapper">
      <svg ref={svgRef}>
        <g id="mainGroup"></g>
      </svg>
    </div>
  );
}

export default EngagementChart;
