import * as d3 from 'd3';
import { useState, useEffect } from 'react';

export const useData = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const promises = [
			d3.csv('https://pub-1caf5c0edc254c58ae6fbd6c190178ea.r2.dev/salesActivity.csv', d3.autoType),
			d3.csv('https://pub-1caf5c0edc254c58ae6fbd6c190178ea.r2.dev/properties.csv', d3.autoType),
		];

		// console.log("started loading", new Date().getMilliseconds());

		Promise.all(promises).then((data) => {
			setData({ sales: data[0], properties: data[1] });
			setLoading(false);
		});

		console.debug('useData.js; fetched ', new Date().getMilliseconds());

		return () => undefined;
	}, []);

	return {
		data,
		loading,
	};
};
