//import d3
import * as d3 from "d3";
import { useState, useEffect, useRef } from "react";
import { TreemapComponent } from "./TreemapComponent";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

const margin = {
  top: 40,
  right: 80,
  bottom: 60,
  left: 80,
};

function Treemap({ data, propName }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const wrapDim = useResizeObserver(wrapperRef);

  // console.log("data", data);
  // prepare a color scale
  // const color = d3
  //   .scaleOrdinal()
  //   .domain(["Apartment", "Retail", "Industrial", "Office", "Hotel"])
  //   .range(["#402D54", "#D18975", "#8FD175"]);

  // console.log("root", root);

  useEffect(() => {
    if (!wrapDim) return;

    const svg = d3.select(svgRef.current);
    const mainGroup = svg.select("#mainGroup");

    const width = wrapDim.width;
    const height = wrapDim.height;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("preserveAspectRatio", "xMidYMid meet");

    console.log("entering with key: ", propName);

    const propertyTypes = d3.groups(data, (d) => d[propName]);

    const hierData = {
      name: "Properties",
      children: propertyTypes.map((d) => {
        return {
          name: d[0] || "Unknown",
          value: d[1].length,
        };
      }),
    };

    console.log("hierData", hierData);

    const root = d3.hierarchy(hierData);
    // root.sum((d) => (d.hasOwnProperty("value") ? d.value : 0));

    mainGroup.selectAll("*").remove();

    TreemapComponent(root, mainGroup, {
      value: (d) => d.data.value,
      group: (d) => d.data.name,
      label: (d, n) => `${d.data.name}\n${n.value.toLocaleString("en")}`,
      title: (d, n) => `${d.data.name}\n${n.value.toLocaleString("en")}`, // text to show on hover
      tile: d3.treemapBinary,
      fillOpacity: 1,
      width: width,
      height: height,
      paddingInner: 2,
      paddingOuter: 2,
    });
  }, [wrapDim]);

  return (
    <div ref={wrapperRef} className="treemap-wrapper">
      <svg ref={svgRef}>
        <g id="mainGroup"></g>
      </svg>
    </div>
  );
}

export default Treemap;
