import * as d3 from "d3";
import { useState, useEffect, useRef } from "react";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

const margin = {
  top: 40,
  right: 40,
  bottom: 40,
  left: 40,
};

const weekday = "sunday";

function CalendarHeatmap({ data }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const wrapDim = useResizeObserver(wrapperRef);

  useEffect(() => {
    if (!wrapDim) return;

    const svg = d3.select(svgRef.current);
    const mainGroup = svg.select("#mainGroup");

    const width = wrapDim.width;
    const height = wrapDim.height;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("preserveAspectRatio", "xMidYMid meet");

    const cellSize = (width - margin.left - margin.right) / 53;
    const countDay = weekday === "sunday" ? (i) => i : (i) => (i + 6) % 7;
    const timeWeek = weekday === "sunday" ? d3.utcSunday : d3.utcMonday;

    const formatDate = d3.utcFormat("%x");
    const formatMonth = d3.utcFormat("%b");
    const formatDay = (i) => "SMTWTFS"[i];

    const yearAgo = new Date();
    yearAgo.setFullYear(yearAgo.getFullYear() - 1);
    yearAgo.setMonth(0);
    yearAgo.setDate(1);

    const filteredData = data
      .filter((d) => d.Activity_Date__c > yearAgo)
      .sort((a, b) => a.Activity_Date__c - b.Activity_Date__c);

    //add a "date" property to each data point, which is a Date object with the time set to 12:00
    filteredData.forEach((d) => {
      // d.Activity_Date__c.setH
      d.date = new Date(d.Activity_Date__c.toDateString());
      d.date.setHours(12);
      // console.log(d.date, d.Activity_Date__c);
    });

    let startDate = filteredData[0].Activity_Date__c;
    let endDate = filteredData[filteredData.length - 1].Activity_Date__c;
    let emptyDays = d3.timeDay.range(startDate, endDate);

    const pathMonth = (t) => {
      const n = weekday === "weekday" ? 5 : 7;
      const d = Math.max(0, Math.min(n, countDay(t.getUTCDay())));
      const w = timeWeek.count(d3.utcYear(t), t);
      return `${
        d === 0
          ? `M${w * cellSize},0`
          : d === n
          ? `M${(w + 1) * cellSize - 1},0`
          : `M${(w + 1) * cellSize - 2},0V${d * cellSize -1}H${
              w * cellSize -1
            }`
      }V${n * cellSize}`;
    };

    const color = d3.scaleDiverging(
      [
        1000, //d3.max(byDaySimplified, (d) => d.value),
        500, //d3.mean(byDaySimplified, (d) => d.value),
        0,
      ],
      // d3.interpolateSpectral
      d3.interpolateYlGnBu
    );

    const dayGrouped = d3.groups(filteredData, (d) => d.date);

    let dataByDays = [];

    emptyDays.forEach((date) => {
      let items = dayGrouped.filter(
        (d) => d[0].toDateString() === date.toDateString()
      );

      if (items.length === 0) {
        dataByDays.push([date, []]);
      } else dataByDays.push(items[0]);
    });

    const byDaySimplified = dataByDays.map((d) => {
      return { date: d[0], value: d[1].length };
    });

    const calendarData = d3.groups(
      byDaySimplified.filter((d) => d.date >= new Date(2014, 0, 1)),
      (d) => d.date.getFullYear()
    );

    const chosen_years = calendarData;

    mainGroup.selectAll("*").remove();

    const year = mainGroup
      .selectAll("g")
      .data(chosen_years)
      .join("g")
      .attr(
        "transform",
        (d, i) => `translate(40.5,${height * i + cellSize * 1.5})`
      );

    year
      .append("text")
      .attr("x", -5)
      .attr("y", -5)
      // .attr("font-weight", "bold")
      .attr("text-anchor", "end")
      .text(([key]) => key);

    year
      .append("g")
      .attr("text-anchor", "end")
      .selectAll("text")
      .data(weekday === "weekday" ? d3.range(1, 6) : d3.range(7))
      .join("text")
      .attr("x", -5)
      .attr("y", (i) => (countDay(i) + 0.5) * cellSize)
      .attr("dy", "0.31em")
      .text(formatDay);

    year
      .append("g")
      .selectAll("rect")
      .data(
        weekday === "weekday"
          ? ([, values]) =>
              values.filter((d) => ![0, 6].includes(d.date.getUTCDay()))
          : ([, values]) => values
      )
      .join("rect")
      .attr("width", cellSize - 4)
      .attr("height", cellSize - 4)
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("opacity", 0.7)
      .attr(
        "x",
        (d) => timeWeek.count(d3.utcYear(d.date), d.date) * cellSize + 0.5
      )
      .attr("y", (d) => countDay(d.date.getUTCDay()) * cellSize + 0.5)
      .attr("fill", (d) => color(d.value))
      .append("title")
      .text(
        (d) => `${formatDate(d.date)}
${d.value} activites`
      );

    const month = year
      .append("g")
      .selectAll("g")
      .data(([, values]) =>
        d3.utcMonths(
          d3.utcMonth(values[0].date),
          values[values.length - 1].date
        )
      )
      .join("g");

    month
      .filter((d, i) => i)
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "lightgrey")
      .attr("stroke-opacity", 0.45)
      // .attr("stroke-dasharray", "2,2")
      .attr("stroke-width", 1.5)
      .attr("d", pathMonth);

    month
      .append("text")
      .attr(
        "x",
        (d) => timeWeek.count(d3.utcYear(d), timeWeek.ceil(d)) * cellSize + 2
      )
      .attr("y", -5)
      .text(formatMonth);

    // console.log("filtered grouped", dataByDays);
  }, [wrapDim]);

  return (
    <div ref={wrapperRef} className="calendar-heatmap-wrapper">
      <svg ref={svgRef}>
        <g id="mainGroup"></g>
      </svg>
    </div>
  );
}

export default CalendarHeatmap;
