import * as d3 from "d3";
import { useState, useEffect, useRef } from "react";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

const margin = {
  top: 40,
  right: 80,
  bottom: 60,
  left: 80,
};

const angleGen = d3
  .pie()
  .value((d) => d)
  .startAngle(-Math.PI / 2);

const arcGen = d3
  .arc()
  .innerRadius(120)
  .outerRadius(190)
  .startAngle((d) => d.startAngle)
  .endAngle((d) => d.endAngle)
  // .cornerRadius(12)
  .padAngle(0.01);

function Donut({ data, propName }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const wrapDim = useResizeObserver(wrapperRef);

  useEffect(() => {
    if (!wrapDim) return;

    const svg = d3.select(svgRef.current);
    const mainGroup = svg.select("#mainGroup");

    const width = wrapDim.width;
    const height = wrapDim.height;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("preserveAspectRatio", "xMidYMid meet");

    console.log("entering with key: ", propName);

    const propertyTypes = d3.rollups(
      data,
      (v) => v.length,
      (d) => d[propName]
    );

    const total = d3.sum(propertyTypes, (d) => d[1]);
    const center = { x: width / 2, y: height / 2 };

    mainGroup.selectAll("*").remove();

    mainGroup.attr("transform", `translate(${center.x}, ${center.y})`);

    // console.log("angelgen with ", stepData.responses);
    let angles = angleGen(propertyTypes.map((d) => d[1] / total));
    // angles = angles.sort((a, b) => b.index - a.index);

    // console.log("anglres", angles, stepData.responses);

    const arcs = mainGroup.selectAll("path").data(angles);

    arcGen.innerRadius((width / 2) * 0.45).outerRadius((width / 2) * 0.75);

    arcs
      .enter()
      .append("path")
      .each(function (d) {
        this._current = d;
      })
      .attr("d", arcGen)
      .attr("fill", (_, i) => d3.schemeTableau10[i]);

    const valueLabels = mainGroup
      .selectAll(".valueLabel")
      .data(angles)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("dy", 4)
      .classed("valueLabel", true)
      .attr("opacity", (d) => (d.data < 0.02 ? 0 : 1))
      .attr(
        "transform",
        (d) => `translate(${arcGen.centroid(d)[0]}, ${arcGen.centroid(d)[1]})`
      )
      .text((d) => `${d3.format(".0%")(d.value)}`);
  }, [wrapDim]);

  return (
    <div ref={wrapperRef} className="donut-wrapper">
      <svg ref={svgRef}>
        <g id="mainGroup"></g>
      </svg>
    </div>
  );
}

export default Donut;
