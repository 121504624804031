import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#4281D4",
      light: "#679ADC",
      dark: "#2E5A94",
    },
    secondary: {
      main: "#F0C755",
      light: "#F3D277",
      dark: "#A88B3B",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    h2: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    h3: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    h4: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    h5: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    h6: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
  },
  components: {
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'body1',
          color:'text.secondary',
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    }
  }
});
