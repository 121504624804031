import { customTheme } from './theme';
import { CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import DashboardView from './views/DashboardView';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { CloseOutlined } from '@mui/icons-material';

export default function App() {
	const DismissSnackAction = ({ id }) => {
		const { closeSnackbar } = useSnackbar();
		return (
			<IconButton onClick={() => closeSnackbar(id)} size={'small'}>
				<CloseOutlined sx={{ fontSize: 16 }} />
			</IconButton>
		);
	};

	return (
		<ThemeProvider theme={customTheme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
					action={(key) => <DismissSnackAction id={key} />}
				>
					<CssBaseline />
					<DashboardView />
				</SnackbarProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
