import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const SavedView = (props) => {
	const { name, chart, info, isEditable = false } = props;
	const renderCardInfo = ({ title, value }) => {
		return (
			<>
				<Grid item xs="2" m="1" lg="2">
					<Typography variant={'body2'} color={'text.secondary'}>
						{`${title}:`}
					</Typography>
				</Grid>
				<Grid item xs="auto">
					{typeof value === 'string' ? <Typography variant={'body2'}>{value}</Typography> : value}
				</Grid>
				<Box width="100%" />
			</>
		);
	};

	return (
		<Grid item xs="6" lg="4">
			<Card>
				<Stack direction={'row'} p={2} spacing={1} height={64} alignItems={'center'}>
					{isEditable ? (
						<TextField
							defaultValue={name}
							variant="standard"
							fullWidth={true}
							InputProps={{
								sx: { fontSize: 20, fontWeight: 500 },
								endAdornment: (
									<InputAdornment position="end">
										<Edit color={'disabled'} />
									</InputAdornment>
								),
							}}
						/>
					) : (
						<Typography variant={'h6'}>{name}</Typography>
					)}
					{isEditable && (
						<IconButton color={'error'}>
							<Delete />
						</IconButton>
					)}
				</Stack>
				<CardContent sx={{ pt: 2 }}>
					<Stack spacing={2}>
						<Grid container rowSpacing={'6px'} alignItems="center" columns={10}>
							{info.map((infoItem) => renderCardInfo(infoItem))}
						</Grid>
						<Box height={250} overflow={'hidden'}>
							{chart}
						</Box>
					</Stack>
				</CardContent>
				<CardActions>
					<Button variant="text" fullWidth={true}>
						View
					</Button>
				</CardActions>
			</Card>
		</Grid>
	);
};

export default SavedView;
