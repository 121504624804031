import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import { useData } from '../../useData';
import EngagementChart from '../../EngagementChart';
import SavedView from '../SavedView';
import Treemap from '../../Treemap';

const SavedViews = () => {
	const { data, loading } = useData();

	const views = [
		{
			name: "Veli's last week of medical properties",
			chart: !loading && <EngagementChart data={data.sales} />,
			info: [
				{
					title: 'Author',
					value: (
						<Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
							<Avatar
								alt={'John Smith'}
								sx={{ width: 24, height: 24 }}
								src={
									'https://images.unsplash.com/photo-1676017030149-4386669c189d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=60'
								}
							/>
							<Typography variant={'body2'}>John Smith</Typography>
						</Stack>
					),
				},
				{ title: 'Created', value: '01/01/2023' },
			],
			isEditable: true,
		},
		{
			name: 'My last week',
			chart: !loading && <Treemap data={data.properties} propName="Property Type" />,
			info: [
				{
					title: 'Author',
					value: (
						<Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
							<Avatar
								alt={'John Smith'}
								sx={{ width: 24, height: 24 }}
								src={
									'https://images.unsplash.com/photo-1676017030149-4386669c189d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=60'
								}
							/>
							<Typography variant={'body2'}>John Smith</Typography>
						</Stack>
					),
				},
				{ title: 'Created', value: '01/01/2023' },
			],
			isEditable: false,
		},
	];

	return (
		<Stack direction={'column'} width={'100%'} spacing={'20px'}>
			<Typography variant={'h4'}>Saved views</Typography>
			<Box>
				<Grid container spacing={'20px'}>
					{views.map((view) => (
						<SavedView {...view} />
					))}
				</Grid>
			</Box>
		</Stack>
	);
};

export default SavedViews;