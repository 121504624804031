//import d3
import * as d3 from 'd3';
import { InternSet } from 'd3';
import { useState, useEffect, useRef } from 'react';

const useResizeObserver = (ref) => {
	const [dimensions, setDimensions] = useState(null);
	useEffect(() => {
		const observeTarget = ref.current;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setDimensions(entry.contentRect);
			});
		});
		resizeObserver.observe(observeTarget);
		return () => {
			resizeObserver.unobserve(observeTarget);
		};
	}, [ref]);

	return dimensions;
};

// const channels = ['Phone', 'Email', 'Text', 'Meeting', 'Website'];

const channelMap = {
	'Received Call': 'Phone',
	'Completed Call': 'Phone',

	//calls:
	// attempted (mutliple subtypes aggregated)
	// completed
	// not interested
	// interested

	'Received Email': 'Email', //neutral
	'Replied to Email': 'Email', //positive
	'Email Clicked Link': 'Email', //positive -- not here yet, will be tracked
	'Email Replaced': 'Email', //positive -- not tracked anymore
	'Opened Email': 'Email', //positive -- not tracked anymore
	'Opted Out of Email': 'Email', //negative
	'Email Bounced': 'Email', //negative

	'Received Text': 'Text',
	'Answered Text': 'Text',

	//text:
	//send (neutral)
	//click (positive)
	//reply (positive)
	//opt-out (negative)

	'Attended Meeting': 'Meeting',
	'Scheduled Meeting': 'Meeting',

	//scheduled meeting (neutral)
	//attended meeting (positive)
	//did not attend meeting (negative)

	'Visited Page': 'Website',
	'Marketing Ended': 'Website',
	'Added to Marketing': 'Website',
	'Clicked Link': 'Website',
	'Completed Form': 'Website',

	//TBD
};

const margin = {
	top: 40,
	right: 80,
	bottom: 40,
	left: 80,
};

function InboundOutboundEngagement({ data }) {
	const svgRef = useRef();
	const wrapperRef = useRef();
	const wrapDim = useResizeObserver(wrapperRef);

	//create a filteredData array, which contains only the data with the Activity_Date__c within the last year
	const yearAgo = new Date();
	yearAgo.setFullYear(yearAgo.getFullYear() - 1);
	const filteredData = data.filter((d) => d.Activity_Date__c > yearAgo);

	useEffect(() => {
		if (!wrapDim) return;

		const svg = d3.select(svgRef.current);
		const mainGroup = svg.select('#mainGroup');

		const width = wrapDim.width;
		const height = wrapDim.height;

		svg
			.attr('width', width)
			.attr('height', height)
			.attr('viewBox', [0, 0, width, height])
			.attr('preserveAspectRatio', 'xMidYMid meet');
		//   .style("background", "#0d0d0d");

		//group data by day, using the Activity_Date__c property and d3.rollup. take care to group by the date, not the full timestamp
		const activitiesByChannel = d3
			.rollups(
				filteredData,
				(v) => v.length,
				(d) => channelMap[d.Activity_Type__c]
			)
			.sort((a, b) => d3.descending(a[1], b[1]));

		//remove the entry with the "undefined" key
		activitiesByChannel.splice(
			activitiesByChannel.findIndex((d) => d[0] === undefined),
			1
		);

		const channelDataDummy = [
			{
				channel: 'Calls',
				inbound: 100,
				outbound: 120,
			},
			{
				channel: 'Emails',
				inbound: 243,
				outbound: 411,
			},
			{
				channel: 'Meetings',
				inbound: 42,
				outbound: 77,
			},
			{
				channel: 'Texts',
				inbound: 166,
				outbound: 240,
			},
		];

		//construct x scale, and axis, based on the Activity_Date__c property
		const yScale = d3
			.scaleBand()
			.paddingInner(0.45)
			.paddingOuter(0.2)
			.domain(Array.from(channelDataDummy.map((d) => d.channel)))
			.range([margin.top, height - margin.bottom]);

		const stackDomain = ['inbound', 'outbound'];

		const channelDataFlatMap = stackDomain.flatMap((direction) =>
			channelDataDummy.map((d) => ({ channel: d.channel, direction, value: d[direction] }))
		);

		// console.log(channelDataFlatMap);

		const X = d3.map(channelDataFlatMap, (d) => d.channel);
		const Y = d3.map(channelDataFlatMap, (d) => d.value);
		const Z = d3.map(channelDataFlatMap, (d) => d.direction);

		let xDomain = X;
		let zDomain = Z;

		xDomain = new InternSet(xDomain);
		zDomain = new InternSet(zDomain);

		// Omit any data not present in the x- and z-domains.
		const I = d3.range(X.length).filter((i) => xDomain.has(X[i]) && zDomain.has(Z[i]));

		const series = d3
			.stack()
			.keys(zDomain)
			.value(([x, I], z) => Y[I.get(z)])
			.order(d3.stackOrderNone)
			.offset(d3.stackOffsetDiverging)(
				d3.rollup(
					I,
					([i]) => i,
					(i) => X[i],
					(i) => Z[i]
				)
			)
			.map((s) => s.map((d) => Object.assign(d, { i: d.data[1].get(s.key) })));

		let yDomain = d3.extent(series.flat(2));

		const xScale = d3
			.scaleLinear()
			.domain(yDomain.reverse())
			.range([width - margin.left, margin.right])
			.clamp(true);

		const color = d3.scaleOrdinal(zDomain, ['#F0CAA3', '#C060A1']);
		const xAxis = d3.axisTop(xScale).ticks(4);
		const xAxisGroup = mainGroup.selectAll('.x-axis').data([null]);

		xAxisGroup
			.enter()
			.append('g')
			.attr('class', 'axis x-axis')
			.merge(xAxisGroup)
			.attr('transform', `translate(0, ${margin.top})`)
			.call(xAxis);
		// .call((g) => g.select('.domain').remove())
		// .call((g) => g.selectAll('.tick line').remove());

		const yFormat = d3.format(',.0f');
		const yAxis = d3.axisLeft(yScale).ticks(5, yFormat);
		const yAxisGroup = mainGroup.selectAll('.y-axis').data([null]);

		yAxisGroup
			.enter()
			.append('g')
			.attr('class', 'axis y-axis')
			.merge(yAxisGroup)
			//   .call(yGrid)
			.attr('transform', `translate(${margin.left}, 0)`)
			.call(yAxis)
			.call((g) => g.select('.domain').remove());
		// .call((g) => g.select('.axis-label').remove());

		//remove ticks

		const formatValue = xScale.tickFormat(100, yFormat);
		const title = (i) => `${X[i]}\n${Z[i]}\n${formatValue(Y[i])}\n\nClick to drill down.`;

		const bar = mainGroup
			.append('g')
			.selectAll('g')
			.data(series)
			.join('g')
			.attr('fill', ([{ i }]) => color(Z[i]))
			.selectAll('rect')
			.data((d) => d)
			.join('rect')

			//add class based on first level of stack
			.attr('class', (d) => {
				return d.data[0];
			})
			// .attr('x', ({ i }) => yScale(X[i]))
			.attr('x', ([x1, x2]) => Math.min(xScale(x1), xScale(x2)))
			.attr('y', ({ i }) => yScale(X[i]))
			// .attr('y', ([y1, y2]) => Math.min(xScale(y1), xScale(y2)))
			// .attr('y', ([y1, y2]) => Math.min(xScale(y1), xScale(y2)))
			// .attr('height', ([y1, y2]) => Math.abs(yScale(y1) - yScale(y2)) - 2)
			.attr('height', yScale.bandwidth())
			// .attr('width', yScale.bandwidth())
			.attr('width', ([x1, x2]) => Math.abs(xScale(x1) - xScale(x2)) - 2)
			.style('cursor', 'pointer')
			.on('mouseover', function (_, d) {
				const curClass = d.data[0];
				mainGroup.selectAll(`rect:not(.${curClass})`).transition().duration(250).style('opacity', 0.1);
			})
			.on('mouseout', function (_, d) {
				const curClass = d.data[0];
				mainGroup.selectAll(`rect:not(.${curClass})`).transition().duration(250).style('opacity', 1);
			});

		// if (title) bar.append('title').text(({ i }) => title(i));

		const legendDiv = d3.select('.legendDiv');
		legendDiv.selectAll('*').remove();
		const legendDivSel = legendDiv.selectAll('div').data([...stackDomain]);

		//append divs and add text
		const entryDiv = legendDivSel.enter().append('div').attr('class', 'legendEntry');

		entryDiv
			.append('svg')
			.append('rect')
			.style('width', '16px')
			.style('height', '16px')
			.style('fill', (d) => color(Z.indexOf(d)));
		entryDiv.append('div').text((d) => d);
	}, [filteredData, wrapDim]);

	return (
		<div className='inbound-outbound-bars'>
			{/* <div className="legendDiv"></div> */}
			<div ref={wrapperRef} className='engagement-wrapper'>
				<svg ref={svgRef}>
					<g id="mainGroup"></g>
				</svg>
			</div>
		</div>
	);
}

export default InboundOutboundEngagement;
