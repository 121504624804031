import {
	Badge,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Drawer,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import SaveIcon from '../../icons/SaveIcon';
import { FilterList } from '@mui/icons-material';
import TrendCard from '../TrendCard';
import TopPeople from '../TopPeople';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';

function createData(id, addressImgUrl, address, addressUrl, owner, type, price, priceTrend) {
	return {
		id,
		address: (
			<Stack direction={'row'} spacing={2}>
				<img style={{ marginTop: '6px', width: 48, height: 32 }} alt={address} src={addressImgUrl} />
				<Link href={addressUrl}>{address}</Link>
			</Stack>
		),
		owner,
		type,
		price,
		priceTrend,
	};
}

const rows = [
	createData(
		1,
		'https://images.unsplash.com/photo-1676834173767-e77528eebbb3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDN8TThqVmJMYlRSd3N8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=60',
		"St George's Ln Singapore",
		'#',
		'Eleanor Pena',
		'Fringilla Nullam',
		'$757.33',
		'success'
	),
	createData(
		2,
		'https://images.unsplash.com/photo-1652207722122-72520983a673?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEwfE04alZiTGJUUndzfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60',
		'52 Bedok Reservoir Cres Singapore 479226',
		'#',
		'Jacob Jones',
		'Quam',
		'$108.10',
		'success'
	),
	createData(
		3,
		'https://images.unsplash.com/photo-1675863415895-a589ef2455c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDExfE04alZiTGJUUndzfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60',
		'Bukit Batok West Ave 9 Singapore',
		'#',
		'Devon Lane',
		'Fermentum Venenatis Tortor',
		'$529.31',
		'warning'
	),
	createData(
		4,
		'https://plus.unsplash.com/premium_photo-1676637000073-5829b51b85ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEzfE04alZiTGJUUndzfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60',
		'Taman Rumpun Bahagia 75300 Melaka',
		'#',
		'Esther Howard',
		'Malesuada Fermentum Tortor',
		'$652.16',
		'success'
	),
	createData(
		5,
		'https://images.unsplash.com/photo-1676625493181-bdc3a338b6dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDE1fE04alZiTGJUUndzfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60',
		'Bidadari Park Drive Singapore',
		'#',
		'Annette Black',
		'Tortor Nullam Fringilla',
		'$656.62',
		'error'
	),
];

const DashboardTemplate = (props) => {
	const { title, chart, subchart, chartTitle, subchartTitle, trends, topPeople } = props;
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [isSaveViewDialogOpen, setSaveViewDialogOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setFilterDrawerOpen(open);
	};

	return (
		<Stack direction={'column'} width={'100%'} spacing={'20px'}>
			{/*title*/}
			<Stack direction={'row'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant={'h4'}>{title}</Typography>
				<Stack direction={'row'} spacing={2} alignItems={'center'}>
					<Button
						variant={'contained'}
						size={'medium'}
						startIcon={<SaveIcon />}
						onClick={() => setSaveViewDialogOpen(true)}
					>
						Save view
					</Button>
					<Badge badgeContent={4} color="secondary" overlap="circular">
						<IconButton color="primary" size={'medium'} onClick={toggleDrawer(true)}>
							<FilterList fontSize={'large'} />
						</IconButton>
					</Badge>
				</Stack>
			</Stack>

			{/*content*/}
			<Box>
				<Grid container spacing={'20px'} columns={10}>
					<Grid item xs={6}>
						<Stack direction={'column'} spacing={'20px'} width={'100%'}>
							<Card>
								<CardHeader title={chartTitle} />
								<CardContent>{chart}</CardContent>
							</Card>
						</Stack>
					</Grid>

					<Grid item xs={4}>
						<Stack direction={'column'} spacing={'20px'} width={'100%'}>
							{subchart && (
								<Card>
									<CardHeader title={subchartTitle} />
									<CardContent>{subchart}</CardContent>
									{/* <CardHeader title={chartTitle} />
									<CardContent>{chart}</CardContent> */}
								</Card>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Box>

			{/*drawer*/}
			<Drawer open={isFilterDrawerOpen} onClose={toggleDrawer(false)} anchor={'right'} elevation={2}>
				<Stack pt={1} pb={1} width={400} height={'100%'}>
					<Stack pl={3} pr={3} height={48} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
						<Typography variant={'h6'}>Filters</Typography>
						<Button variant={'text'}>Reset</Button>
					</Stack>
					<Stack p={3} spacing={2} flex={1}>
						<FormControl fullWidth={true} size={'small'}>
							<InputLabel>Category</InputLabel>
							<Select label="Category" value={'Properties'}>
								<MenuItem value={'Properties'}>Properties</MenuItem>
							</Select>
						</FormControl>

						<FormControl fullWidth={true} size={'small'}>
							<InputLabel>Sub-Category</InputLabel>
							<Select label="Sub-Category" />
						</FormControl>
						<DatePicker
							label="Start date"
							renderInput={(params) => <TextField size={'small'} {...params} />}
							onChange={() => {}}
							value={null}
						/>
						<DatePicker
							label="End date"
							renderInput={(params) => <TextField size={'small'} {...params} />}
							onChange={() => {}}
							value={null}
						/>
					</Stack>
					<Stack pl={3} pr={3} height={48} spacing={2} direction={'row'} alignItems={'center'}>
						<Button variant={'outlined'} fullWidth={true}>
							Cancel
						</Button>
						<Button variant={'contained'} fullWidth={true}>
							Apply filters (2)
						</Button>
					</Stack>
				</Stack>
			</Drawer>

			{/* dialog*/}
			<Dialog onClose={() => setSaveViewDialogOpen(false)} open={isSaveViewDialogOpen}>
				<DialogTitle>Save view</DialogTitle>
				<DialogContent>
					<Box pt={1}>
						<TextField label={'Enter view name'} sx={{ width: 400 }} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant={'text'} onClick={() => setSaveViewDialogOpen(false)}>
						Cancel
					</Button>
					<Button
						variant={'contained'}
						onClick={() => {
							enqueueSnackbar('View is saved successfully!', { variant: 'success' });
							setSaveViewDialogOpen(false);
						}}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default DashboardTemplate;
